h1.pp {
font-family: 'Source Code Pro', monospace;
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 45px;
position: relative;
color: #FFFFFF;
text-align: left;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
justify-content: space-between;
-webkit-align-items: center;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: justify;
-webkit-justify-content: space-between;
padding-top: 128px;
margin: 0 auto;
max-width: 570px;
}

h2.pp {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 180%;
    display: flex;
    position: relative;
    padding-top: 48px;
    margin: 0 auto;
    max-width: 570px;

}

p.pp {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    display: flex;
    position: relative;
    align-self: auto;
    margin: 0 auto;
    padding-top: 24px;
    max-width: 570px;

}
ul.pp, li.pp {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    position: relative;
    align-self: auto;
    margin: 0 auto;
    max-width: 570px;

}


@media (max-width: 425px) {
    h1.pp,h2.pp,li.pp,p.pp,ul.pp { 
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }
}

@media (max-width: 767px) {
    h1.pp,h2.pp,p.pp { 
        max-width: 570px;
        padding-left: 18px;
        padding-right: 18px;
    }
    li.pp {
        max-width: 570px;
    }
    ul.pp {
        max-width: 570px;
        padding-left: 32px;
        padding-right: 18px;
    }
}



@media (max-width: 1599px) {
    h1.pp,h2.pp,li.pp,p.pp,ul.pp { 
        max-width: 580px;
    }
}

 @media (max-width: 1279px) {
    h1.pp,h2.pp,li.pp,p.pp,ul.pp { 
        max-width: 570px;
    }
}

 @media (max-width: 319px) {
    h1.pp,h2.pp,p.pp { 
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }
    li.pp {
        width: 100%;
    }
    ul.pp {
        width: 100%;
        padding-left: 32px;
        padding-right: 12px;
    }
}

@media (max-width: 373px) {
    h1.pp,h2.pp,p.pp { 
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }
    li.pp {
        width: 100%;
    }
    ul.pp {
        width: 100%;
        padding-left: 32px;
        padding-right: 12px;
    }
}
